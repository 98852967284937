import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The 34th International Geological Congress in Brisbane attracted almost 6000
delegates from all  over the world. Colin Reeves contributed a paper to the
special session on the role of geological  surveys in the development of
resources under the title: `}<strong parentName="p">{`Modern technology: opportunity or threat for failing
geological surveys?`}</strong>{` The talk was deliberately controversial and drew  supportive
reactions from African delegates who recognised the truth about the parlous
state of  many national geological surveys in Africa. A PDF file of the talk is
available upon request to  Earthworks. `}</p>
    <p>{`The abstract of the talk appears below. A longer, illustrated text on this topic
may be downloaded  via the item Earthworks 2001-2011 under the News tab above. `}</p>
    <blockquote>
      <p parentName="blockquote">{`More than 40 years’ experience with geological surveys in Africa and elsewhere
gives cause for concern over  their future. Technological change brings two
powerful new tools to their function: (1) high resolution airborne
geophysical surveys that can accelerate geological reconnaissance of whole
countries and provide geoscience  data attractive to the global exploration
industry; (2) ICT that makes virtually instantaneous map, data and  report
distribution possible to desktops worldwide. Internet penetration has been
undeniably tardy in Africa but  there has been little appetite so far for its
exploitation as a means of publication and self-promotion by  geological
surveys. Few have even a website that would pass muster by international
norms. The main  obstacles, it seems, are operational or administrative rather
than technical or scientific. Personal experience of  several hundred
postgraduate students mostly from national geological surveys in the
less-advantaged  countries is that the more capable quickly tire of the lack
of career path on their return home and they then  become economic migrants or
take premature retirement. The business model under which geological surveys
operate is often punitive; ‘showing revenue’ is not a primary goal that would
suit even the most successful of  western-style surveys. At another level,
they often suffer the lack of efficiency and efficacy of a wider  government
machine that fails its people more generally. Until things change, geological
surveys risk both aid weariness on the part of donors and the rise of third
parties elsewhere usurping the distribution of what should  be their sovereign
data. `}</p>
    </blockquote>
    <p>{`The picture below is of the Geological Survey offices in Lobatse, Botswana, as
they were in 1973.`}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      